import React from 'react';
import NavBar from '../Components/NavBar';


export default function HealthWiki() {
  return (
    <>
     <NavBar/>
   <div className="container my-2" style={{color :'#DC143C'}} >
        <h1 clasName="my-3"><b>Health Wiki</b></h1>
                <p>Welcome to <b>Health Wiki - </b> 
                     In this section you'll get an idea about periods/menstruation. Explore the options below:</p>
                <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne" >
                <button className="accordion-button"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color :'#DC143C', fontWeight: "bold"}}>
                What is periods or menstruation?
                </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                <div className="accordion-body" style={{color :'#DC143C'}} >
               
The menstrual cycle is a natural process that occurs in a woman's body each month as a way of preparing for pregnancy. During this time, the uterus sheds its lining, causing menstrual blood to flow out of the body through the cervix and vagina. For the majority of women, the menstrual cycle lasts between three to five days. It is an essential process that regulates hormones and can indicate important health information. Tracking menstrual cycles can be crucial for women's health, and the use of period tracker applications can assist with monitoring and predicting cycles for a better understanding of one's reproductive health.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{color :'#DC143C', fontWeight: "bold"}}> 
                What is the regular period/menstruation cycle?
                </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                <div className="accordion-body" style={{color :'#DC143C'}} >
                A menstruation cycle is the normal regular cycle of menstruation or periods occurring every month in a woman, signifying that all the important parts of the body are functioning properly. <br/>

The menstruation cycle prepares the body for pregnancy every month and also helps cause hormonal changes in the body to keep a woman healthy. <br/>

Hormones are chemicals generated by glands in the body that controls most of the major bodily functions, from simple basic needs like hunger to complex systems like reproduction, and even the emotions and mood. In a woman, among other hormones, estrogen and progesterone are the key hormones produced by the ovaries. <br/>

The average menstrual cycle among women is usually 28 days. The first day of the menstrual cycle is when blood starts to flow out from the vagina. 
The complete duration of a menstrual/period cycle can be divided into four main phases:<br/>

- Menstrual phase (From day 1 to 5)<br/>
- Follicular phase (From day 1 to 13)<br/>
- Ovulation phase (Day 14)<br/>
- Luteal phase (From day 15 to 28)<br/>
On day one of the menstruation cycle, or the first day of your period, there is very little circulating estrogen or progesterone in the body. 
From days three to seven, the ovaries start to secrete estrogen, which directs your period (or the bleeding) to slow down and stop. Estrogen also guides your ovaries to start maturing a follicle that develops into an egg during ovulation. Therefore this part of the cycle is known as the follicular phase. 
Thereafter, estrogen levels continue to rise until around day 14, which is when the follicle releases an egg from the ovary. This is known as the ovulation phase. 
After the ovulation phase, the second half of the menstrual cycle begins, which is the luteal phase. During this phase both estrogen and progesterone begin to rise and then peak after about 7-10 days. It is during this time that many girls and women experience what is known as symptoms of Premenstrual Syndrome (PMS). 
After the peak, both estrogen and progesterone fall, triggering your next period to begin. This marks the end of one menstrual cycle and the beginning of the next one.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{color :'#DC143C', fontWeight: "bold"}}>
                What are the symptoms that occur before the periods/menstruation cycle?
                </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}} >
                Some women experience symptoms before or while menstruating. This is called Premenstrual Syndrome (PMS). <br/>

                  Some common symptoms triggered by the two main hormones, estrogen and progesterone are: <br/>

                  Estrogen effects:<br/>

                 ~ bloating<br/>
                 ~ changes in sleeping pattern<br/>
                 ~ mood swings<br/>
                 ~ breast cysts<br/>
                 ~ headaches<br/>

                  Progesterone effects: <br/>

                 ~ acne<br/>
                 ~ increased hunger<br/>
                 ~ fatigue<br/>
                 ~ irritability<br/>
                 ~ tenderness of breasts<br/>
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{color :'#DC143C', fontWeight: "bold"}}>
                What occurs during a period/menstrual cycle?
                </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                This is how a menstrual cycle runs:<br/>

- The first day of the period (when the bleeding begins) marks the day one of the cycle.  This occurs after both your estrogen and progesterone levels fall at the end of the previous cycle, directing the blood and tissues lining the uterus to break down and flow out from the body. The bleeding lasts for about 5 days.<br/>
- For most women, the bleeding completely stops by the seventh day. During this time, leading up to day 13, also known as the follicular phase, the hormone estrogen also guides the ovaries to start maturing a follicle within which lies an egg.<br/>
- Between day seven and 14, as the follicle continues to develop to reach maturity, the lining of the uterus thickens as it gets rich in blood and nutrients, waiting for a fertilized egg to be implanted there.<br/>
- For most women approximately on day 14, the mature follicle bursts and releases an egg from the ovary. This is the ovulation process.<br/>
- In the next few days, the egg travels down the fallopian tube towards the uterus or the womb. If the egg meets and unites with a sperm in the uterus, the fertilized egg continues its journey down the fallopian tube and attaches itself to the lining of the uterus which at this time is rich in blood and nutrients.<br/>
- Alternatively, during this period if the egg does not fertilize, the hormone levels (estrogen and progesterone levels) fall at around day 25  triggering the next menstrual cycle to begin approximately by day 28 for most women. The unfertilized egg breaks down and passes out of the body during this next cycle.<br/>
  
                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style={{color :'#DC143C', fontWeight: "bold"}}>
                What occurs during a period/menstrual cycle?
                </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                This is how a menstrual cycle runs:<br/>

- The first day of the period (when the bleeding begins) marks the day one of the cycle.  This occurs after both your estrogen and progesterone levels fall at the end of the previous cycle, directing the blood and tissues lining the uterus to break down and flow out from the body. The bleeding lasts for about 5 days.<br/>
- For most women, the bleeding completely stops by the seventh day. During this time, leading up to day 13, also known as the follicular phase, the hormone estrogen also guides the ovaries to start maturing a follicle within which lies an egg.<br/>
- Between day seven and 14, as the follicle continues to develop to reach maturity, the lining of the uterus thickens as it gets rich in blood and nutrients, waiting for a fertilized egg to be implanted there.<br/>
- For most women approximately on day 14, the mature follicle bursts and releases an egg from the ovary. This is the ovulation process.<br/>
- In the next few days, the egg travels down the fallopian tube towards the uterus or the womb. If the egg meets and unites with a sperm in the uterus, the fertilized egg continues its journey down the fallopian tube and attaches itself to the lining of the uterus which at this time is rich in blood and nutrients.<br/>
- Alternatively, during this period if the egg does not fertilize, the hormone levels (estrogen and progesterone levels) fall at around day 25  triggering the next menstrual cycle to begin approximately by day 28 for most women. The unfertilized egg breaks down and passes out of the body during this next cycle.<br/>
  
                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" style={{color :'#DC143C', fontWeight: "bold"}}>
                For how long does a period/menstruation normally continue in a month?

                </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                For most women, a period lasts for around approximately five days. 


                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingSeven">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" style={{color :'#DC143C', fontWeight: "bold"}}>
                How long does a woman continue menstruating in her life? 
                </button>
                </h2>
                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                Women continue menstruating till they reach a phase in their lives known as menopause which usually occurs between the ages 45 - 50 years. 
                <br/>Some women may experience early menopause due to medical reasons such as undergoing hysterectomy. 
  
                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingEight">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight" style={{color :'#DC143C', fontWeight: "bold"}}>
                Why are periods/menstruation cycles irregular in some women?
                </button>
                </h2>
                <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                For some women, the time between periods and the amount of blood shed during menstruation vary considerably. This is known as irregular menstruation. Irregular menstruation may have different causes such as:<br/>

               ~ hormonal imbalances (may be due to life cycle changes such as pregnancy, childbirth, breastfeeding, and menopause)<br/>
               ~ sudden and extreme weight loss<br/>
               ~ sudden and extreme weight gain<br/>
               ~ stress<br/>
               ~ using contraceptives<br/>
               ~ eating disorders, such as anorexia or bulimia<br/>
               ~ suffering from disorders such as PCOS, thyroid disorder, endometriosis, pelvic inflammatory disease (PID), sexually transmitted infections (STIs), diabetes<br/>
               ~ cancers such as cervical cancer or uterine cancer<br/>
                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingNine">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine" style={{color :'#DC143C', fontWeight: "bold"}}>
                For what reasons can a woman miss her periods or monthly menstruation cycle?
                </button>
                </h2>
                <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                A  woman may experience late period or miss periods due to a variety of reasons such as:<br/>

                pregnancy<br/>
                stress<br/>
                sudden loss of weight<br/>
                sudden weight gain<br/>
                suffering from disorders such as PCOS, thyroid disorder, endometriosis, pelvic inflammatory disease (PID), sexually transmitted infections (STIs), diabetes<br/>
                using birth control<br/>
                early peri-menopause<br/>
                thyroid issues<br/>
  
                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTen">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen" style={{color :'#DC143C', fontWeight: "bold"}}>
                Why do some women experience menstruation/period cramps/pains? 
                </button>
                </h2>
                <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                Women can experience pains or cramps during menstruations due to various reasons such as: <br/>

                  Stress <br/>
                  Poor physical health <br/>
                  Lack of physical activity <br/>
                  Dehydration <br/>
                  Endometriosis <br/>
                  Adenomyosis <br/>
                  Uterine fibroids <br/>
                  Insertion of copper IUD for birth control <br/>
                  Pelvic inflammatory disease <br/>
                  Uterine defects <br/>
  
                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingEleven">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" style={{color :'#DC143C', fontWeight: "bold"}}>
                How often should a girl change her sanitary pad/tampon during her periods or while she is menstruating?
                </button>
                </h2>
                <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                You should change your sanitary pad before it becomes completely soaked with blood and unable to retain the blood. If you use a tampon you should change it every 4 to 8 hours. A girl will easily understand when she needs to change her pad/tampon. 


                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTewlve">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" style={{color :'#DC143C', fontWeight: "bold"}}>
                How long does a woman continue menstruating in her life? 
                </button>
                </h2>
                <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                Women continue menstruating till they reach a phase in their lives known as menopause which usually occurs between the ages 45 - 50 years. 
                <br/>Some women may experience early menopause due to medical reasons such as undergoing hysterectomy. 
  
                  </div>
                </div>
      
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingThirteen">
                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen" style={{color :'#DC143C', fontWeight: "bold"}}>
                For what kind of periods/menstruation related problems should a doctor be consulted? 

                </button>
                </h2>
                <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color :'#DC143C'}}  >
                You need to consult a doctor if you experience the following symptoms related to menstruation: <br/>

                 - you have not started menstruating by the age of 16 years<br/>
                 - you have not started menstruating within 3 years after your breast growth began<br/>
                 - your breasts have not begun to grow after 13 years of age<br/>
                 - unusually heavy flow/bleeding<br/>
                 - experiencing high fever<br/>
                 - you are experiencing sudden irregular periods after years of having regular periods<br/>
                 - nausea and vomiting<br/>
                 - severe pain or cramps<br/>
                 - bleeding that lasts longer than seven days<br/>
                 - you are experiencing periods more often than 21 days or less often than 35 days<br/>
                 - your periods have stopped for more than 90 days and you are still not of menopausal age<br/>
                 - spotting occurs for a long time after periods should be ideally over in a month<br/>
                 - you experience bleeding between periods<br/>
                 - sudden bleeding occurs after you have undergone menopause<br/>
  
                  </div>
                </div>
      
            </div>
            </div>
        </div>
    </>
  );
}
